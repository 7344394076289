import React from "react"
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from "../components/layout"
import Button from "../components/button"
import Seo from "../components/seo"
//import { Fade } from "react-reveal" 

const ContactForm = styled.form`
  display: flex;
  align-items: flex-start;
  flex-direction:column;
  max-width:650px;
  margin:0 auto;
  label{
    margin-bottom:0.4rem;
  }
  input, textarea {
    border:0;
    width:100%;
    margin-bottom:2.6rem;
    border-radius:0.8rem;
    padding:1rem 1rem;
    background-color: #EAF4F3;
  }
  input[type="text"] {
    height:5rem;
  }

  h2 {
    color:${props => props.theme.colors.sandlight};
    margin-bottom:2rem;
  }
  `

const Contact = ({data}) => {
  return (
  <Layout>
    <Seo meta={data.contact.seoMetaTags} />
    <h1 className="breadcrumb">Contact</h1>
    <div className="container maxwidth-800 contact-page">
    <div dangerouslySetInnerHTML={{__html: data.contact.content}}/>

    </div>
    <section className="full-green">
      <div className="container">
          <ContactForm
            name="Formulaire de contact"
            action="/succes"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
          //	className={"btn-group pull-right " + (this.props.showBulkActions ? 'show' : 'hidden')}
          >
            <h2>Contacter iAROSS</h2>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="Formulaire de contact" />
            <label htmlFor="name">Nom</label>
            <input placeholder="Nom" type="text" name="name" id="name" required />
            <label htmlFor="email">Email</label>
            <input placeholder="Email" type="text" name="email" id="email" required />
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="6" required ></textarea>
            <Button type="submit" value="Envoyer" button>Envoyer</Button>


          </ContactForm>
      </div>
    </section>
  </Layout>
  )
}

export default Contact


export const query = graphql`
  query ContactQuery {
    contact:datoCmsContact {
    content
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
  }
}
`